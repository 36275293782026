import imagesLoaded from 'imagesloaded';
import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';

import gsap from '../lib/gsap.min';
import SplitText from '../lib/SplitText.min';
import ScrollTrigger from '../lib/ScrollTrigger.min';
import ScrollToPlugin from '../lib/ScrollToPlugin.min';
gsap.registerPlugin(SplitText, ScrollTrigger, ScrollToPlugin);
import ColorThief from 'colorthief/dist/color-thief.mjs'

import Swiper from '../lib/swiper-bundle.min';

import lity from 'lity';

export function main() {
	
var Module = {};
var body;

Module.Compornent = {
	mobileToggleAsset: false,
	checkClass: function($this){
		$this = null;
	}
};

(function($) {
Module.Events = (function (){
'use strict';

var OBSERVER = {
	handlers: [],
	observeEvents: function(target){
		this.handlers.push(target);
	},
	deleteListener: function(element,listener){
		element.off(listener);
		element = null;
		listener = null;
	},
	clearEvents: function(){
		$(window).off('scroll resize');
		cancelAnimationFrame(timeReq);
	}
};

var winH = $(window).height();
var winW = $(window).width();
var body = $('body');


function nav_show(){
	body.addClass('navopen');
	navlistAnime();
}
function nav_hide(){
	body.removeClass('navopen');
}
function navInit(){
	nav_current();
	$('#nav_btn').on('click', function(){
		if( body.hasClass('navopen') ){
			nav_hide();
		}else{
			nav_show();
		}
	});
}

function navlistAnime (){
	let menuli = gsap.utils.toArray('#navul li');
	menuli.forEach((el, i) => {
		gsap.from(el, {y:-60,opacity:0, duration:0.3, delay:i*0.02+0.3})
	});
}
function navWhite(){
	gsap.utils.toArray('.nwh').forEach(target => {
		let scrollTrigger = ScrollTrigger.create({
			trigger: target,
			start: "top 40px",
			end: "bottom 40px",
			onToggle: self => {
				if(self.isActive){
					body.addClass('navwh');
				}else{
					body.removeClass('navwh');
				}
			},
			onUpdate: self => {
			}
		});
	});
}


var nav = $("#nav");
var $navLi = nav.find("li");
function nav_current(){
	$navLi.removeClass("on");
}


const setVH_Height = () => {
	const vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', vh+'px');
}
const setVH = () => {
	const navvh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--kvvh', navvh+'px');
	const vw = window.innerWidth * 0.01;
	document.documentElement.style.setProperty('--vw', vw+'px');
}
setVH_Height();
setVH();
window.addEventListener('resize', function () {
	if(window.matchMedia('(min-width:768px)').matches){
		setVH_Height();
	}
	setVH();
});


let EL_scrollTrigger;
function elFunc(EL){
	gsap.utils.toArray(EL).forEach(target => {
		if(window.matchMedia('(max-width:767px)').matches){
			if(target.classList.contains('nosp')){
				return;
			}
		}
		if(!target.classList.contains('nomove')){
			target.style.opacity = 0;
		}
		EL_scrollTrigger = ScrollTrigger.create({
			trigger: target,
			start: "top bottom",
			once: true,
			onEnter: self => {
				elAnime(target);
			},
		});

		const navh = document.getElementById('nav').clientHeight;
		const up_scrollTrigger = ScrollTrigger.create({
			trigger: 'body',
			start: "top top",
			onUpdate: self => {
				const _slfscrl = self.scroll();
				if(self.direction == -1){
					document.body.classList.remove('scrldown');
				}else{
					if(_slfscrl > navh){
						if(self.prevSclrl < _slfscrl-1){
							document.body.classList.add('scrldown');
						}
						self.prevSclrl = _slfscrl;
					}
				}
			},
		});		
		// if(target.classList.contains('elp')){
		// 	var elc = target.querySelectorAll('.elc');
		// }else{
		// 	var elc = [target];
		// }
		// gsap.fromTo(elc,
		// 	{ y: 120, autoAlpha: 0 },
		// 	{ y: 0, autoAlpha: 1, ease: 'none',
		// 		scrollTrigger: {
		// 			trigger: target,
		// 			start: 'top bottom',
		// 			end: "+=100%",
		// 			scrub: true,
		// 		},
		// 		stagger: 0.1,
		// 	}
		// );
	});
}

function elAnime(target){
	target.style.opacity = 1;
	if(target.classList.contains('elp')){
		var elc = target.querySelectorAll('.elc');
	}else{
		var elc = [target];
	}
	elc.forEach((elc, i) => {
		let _y = 120;
		let _opa = 0;
		if(elc.classList.contains('nomove')){
			_y = 0;
			_opa = 1;
		}
		gsap.fromTo(elc,{y:_y,opacity:_opa},{
			y: 0,
			opacity:1,
			duration: 0.8,
			delay: i * 0.2,
			ease:'power1',
			onComplete: function(){
				const _txef = elc.querySelectorAll('.txef');
				if(_txef){
					txtEffect(_txef);
				}
				elc.classList.add('elon');
			}
		});
	});
}

function txtEffectInit (txef){
	gsap.utils.toArray(txef).forEach((el,i) => {
		if(window.matchMedia('(max-width:767px)').matches){
			if(el.classList.contains('nosp')){
				return;
			}
		}
		let mySplitText = new SplitText(el, { type: "chars" });
		let chars = mySplitText.chars;
		chars.forEach((char, i) => {
			gsap.set(char, {opacity:0});
		});
	});
}

function txtEffect (target, state){
	let _val = ['2em',0,0,1];
	if(state === false){
		_val = [0,'-2em',1,0];
	}
	target.forEach((tar) => {
		let chars = tar.querySelectorAll('div');
		chars.forEach((char, i) => {
			gsap.fromTo(char, 
				{y:_val[0],opacity:_val[2]},{
				y: _val[1],
				opacity:_val[3],
				duration: 0.6,
				delay: i * 0.02,
			});
		});
	});
}

function singleH1(){
	const target = document.getElementById('singlekv');
	const _txef = target.querySelectorAll('.txef');
	let scrollTrigger = ScrollTrigger.create({
		trigger: target,
		start: "top 40px",
		end: "+=100",
		onToggle: self => {
			if(self.isActive){
				txtEffect(_txef, true);
			}else{
				txtEffect(_txef, false);
			}
		},
		onUpdate: self => {
		}
	});
}


function parallaxImage() {
	gsap.utils.toArray('.paraimg .img').forEach((paraimg, i) => {
		const heightDiff = paraimg.offsetHeight - paraimg.parentElement.offsetHeight;
		gsap.fromTo(paraimg,{y:-heightDiff}, {
			scrollTrigger: {
				trigger: paraimg.parentElement,
				scrub: true
			},
			y: 0,
			ease: "none"
		});
	});
}


async function ScrollSmootherKill() {
	await new Promise((resolve, reject) => {
		ScrollTrigger.killAll();
		resolve();
	})
}


var tar_offset;
function pageScroll(){
	if(window.matchMedia('(max-width:767px)').matches){
		tar_offset = 0;
	}else{
		tar_offset = 0;
	}
	$('a.anchor').on('click', function(){
		nav_hide();
		let href = this.hash;
		gsap.to(window, { duration:0.6, scrollTo:{y:href, autoKill:true, offsetY:tar_offset}, ease:'power2'});

		var hrefPageUrl = $(this).prop("href");
		hrefPageUrl = hrefPageUrl.split("#")[0];
		var currentUrl = location.href;
		currentUrl = currentUrl.split("#")[0];
		if( hrefPageUrl == currentUrl ){
			return false;
		}
	});
	// $('#scrldown').on('click', function(){
	// 	gsap.to(window, { duration:0.6, scrollTo:{y:'.scrltarget', autoKill:true, offsetY:tar_offset}, ease:'power2'});
	// 	return false;
	// });
	$('#btt').on('click', function(){
		gsap.to(window, { duration:0.6, scrollTo:{y:0, autoKill:true}, ease:'power2'});
		return false;
	});
}


function expandFunc() {
	let exbtn = document.querySelectorAll('.exbtn');
	for (let i = 0; i < exbtn.length; i++) {
		exbtn[i].addEventListener('click',function(){
			let parent = this.parentElement;
			const result = parent.classList.contains('open');
			if (result) {
				parent.classList.remove('open');
				const exc = parent.querySelectorAll('.exc');
				gsap.utils.toArray(exc).forEach(ex => {
					gsap.to(ex,{
						height: 0,
						duration:0.3,
						ease: "power2.inOut",
						onComplete: () => {
							ScrollTrigger.refresh();
						}
					});
				});
			}else{
				parent.classList.add('open');
				const exc = parent.querySelectorAll('.exc');
				gsap.utils.toArray(exc).forEach(ex => {
					gsap.to(ex,{
						height:'auto',
						duration:0.3,
						ease: "power2.inOut",
						onComplete: () => {
							ScrollTrigger.refresh();
						}
					});
				});
			}
		})
	}
}

function videoSwitch() {
	var videoelm = document.getElementById('video');
	if(!videoelm) {
		return false;
	}
	var videosp = '_sp.';
	var videopc = '_pc.';
	var videoState = 1;
	if(window.matchMedia('(max-width:767px)').matches){
		if(videoState == 1){
			var srcname = videoelm.getAttribute('src').replace(videopc, videosp);
			videoelm.src = srcname;
			videoState = 0;	
		}
	}else{
		if(videoState == 0){
			var srcname = videoelm.getAttribute('src').replace(videosp, videopc);
			videoelm.src = srcname;
			videoState = 1;	
		}
	}
	videoelm.style.opacity = 1;
}


function getbgColor(img) {
	const colorThief = new ColorThief();
	if(img == null){
		return false;
	}
	let bgcolor;
	if (img.complete) {
		bgcolor = colorThief.getColor(img);
	} else {
		img.addEventListener('load', function() {
			bgcolor = colorThief.getColor(img);
		});
	}
	return bgcolor;
}
function getbgColorFunc() {
	const kvbgimg = document.getElementById('kvbgimg');
	const nextimg = document.getElementById('nextimg');
	const kvbg = 'rgb('+getbgColor(kvbgimg)+')';
	const nextbg = 'rgb('+getbgColor(nextimg)+')';
	if(kvbgimg == null){
		document.documentElement.style.setProperty('--kvbg', '#333');
	}else{
		document.documentElement.style.setProperty('--kvbg', kvbg);
	}
	$('#nextbg').css('background-color', nextbg);
}


let swiperArr = [];
const kvSlider = async () => {
	await new Promise((resolve, reject) => {
		const kvelm = document.getElementById('kv');
		const swiperEl = document.querySelectorAll('.swiper');
		swiperEl.forEach((swiperEl) => {
			const colorChange = (target) => {
				if(target.el.id == 'swiperA'){
					const el = target.el.querySelectorAll('.swiper-slide-active');
					el.forEach((_el) => {
						const color = _el.dataset.color;
						const bg = _el.dataset.bg;
						document.documentElement.style.setProperty('--kvbg', bg);
						if(color==='bk'){
							document.documentElement.style.setProperty('--kvcolor', '#000');
							document.body.classList.add('navbk');
						}else{
							document.documentElement.style.setProperty('--kvcolor', '#fff');
							document.body.classList.remove('navbk');
						}
					});
				}
			}	
			let effect = 'slide';
			if(swiperEl.id === 'swiperA'){
				effect = 'fade';
			}
			const swiper = new Swiper(swiperEl, {
				effect: effect,
				fadeEffect: {
					crossFade: true
				},	
				slidesPerView: 1,
				speed: 800,
				spaceBetween: 0,
				loop: true,
				allowTouchMove: false,
				keyboard: {
					enabled: false,
					onlyInViewport: false,
				},
				on: {
					init: function(swiper){
						colorChange(swiper);
					},
					slideNextTransitionStart: function(swiper){
						colorChange(swiper);
					}
				}
			});
			swiperArr.push(swiper);
		});
		resolve();
	});
}

const moment = require('moment-timezone');
let slideflag = false;
function timeFunc(timezone, date, time) {
	// const now = new Date(londonStandardTime);
	// console.log(now);
	const day = timezone.format('DD');
	const month = timezone.format('MM');
	const year = timezone.format('YYYY');
	const secbase = timezone.format('ss');
	const sec = secbase;
	const min = timezone.format('mm');
	const hour = timezone.format('hh');
	const ampm = timezone.format('A');

	// const day = ('00' + (now.getDate())).slice(-2);
	// const month = ('00' + (now.getMonth() + 1)).slice(-2);
	// const year = now.getFullYear();
	// const secbase = now.getSeconds();
	// const sec = ('00' + (secbase)).slice(-2);
	// const min = ('00' + (now.getMinutes())).slice(-2);
	// const hours = now.getHours();
	// const hour12 = ('00' + (hours % 12)).slice(-2);
	// let hourStr;
	// if(hours < 12){
	// 	hourStr = 'am';
	// }else{
	// 	hourStr = 'pm';
	// }
	date.textContent = day+'/'+month+'/'+year;
	time.textContent = hour+':'+min+':'+sec+' '+ampm;

	if(secbase % 4 == 0){
		if(slideflag){
			swiperArr.forEach((element) => {
				element.slideNext();
			})
			slideflag = false;
		}
	}else{
		slideflag = true;
	}
}
let timeReq;
function timetick() {
	timeReq = requestAnimationFrame(timetick);
	const enTime = moment.tz('Europe/London');
	timeFunc(enTime, date_en, time_en);
	const jpTime = moment.tz('Asia/Tokyo');
	timeFunc(jpTime, date_jp, time_jp);
}


let items_scrollTrigger = null;
function spitemShow(){
	const items = gsap.utils.toArray('.item');
	if(window.matchMedia('(max-width:767px)').matches){
		items.forEach((target,i) => {		
			const _inner = target.querySelector('.inner');
			_inner.style.display = 'none';
			items_scrollTrigger = ScrollTrigger.create({
				trigger: target,
				start: "top top",
				end: "bottom top",
				invalidateOnRefresh: true,
				onEnter: (self) => {
					_inner.style.display = 'block';
				},
				onLeave: (self) => {
					_inner.style.display = 'none';
				},
				onEnterBack: (self) => {
					_inner.style.display = 'block';
				},
				onLeaveBack: (self) => {
					_inner.style.display = 'none';
				},
				// onUpdate: (self) => {
				// 	self.refresh();
				// },
				// onRefresh: (self) => {
				// 	if(self.progress == 1){
				// 		_inner.style.display = 'block';
				// 	}
				// }


				// onEnter: (self) => {
				// 	if(self.progress == 1){
				// 		if(items_scrollTrigger){
				// 			target.classList.add('active');
				// 			_inner.style.display = 'block';
				// 		}
				// 	}
				// },
				// onLeaveBack: (self) => {
				// 	if(items_scrollTrigger){
				// 		target.classList.remove('active');
				// 		_inner.style.display = 'none';
				// 	}
				// },
				// onUpdate: (self) => {
				// 	self.refresh();
				// },
				// onRefresh: (self) => {
				// 	if(self.progress == 1){
				// 		if(items_scrollTrigger){
				// 			target.classList.add('active');
				// 			_inner.style.display = 'block';
				// 		}
				// 	}
				// }
			});
		});
	}else{
		if (items_scrollTrigger) {
			items_scrollTrigger.kill();
			items_scrollTrigger = null;
		}
		items.forEach((target,i) => {
			const _inner = target.querySelector('.inner');
			_inner.style.display = 'block';
			const cat = target.querySelector('.cat');
			const thumb = target.querySelector('.thumb');
			target.classList.remove('active');
			target.removeAttribute('style');
			if (cat.style.opacity !== '') {
				cat.style.removeProperty('opacity');
				cat.style.removeProperty('visibility');
			}
			if (thumb.style.opacity !== '') {
				thumb.style.removeProperty('opacity');
				thumb.style.removeProperty('visibility');
			}
		});
	}
}


function lityFunc() {
	gsap.utils.toArray('.lityelm').forEach(target => {
		const vimeoid = target.dataset.vimeoid;
		target.addEventListener('click', function(){
			console.log(vimeoid);
			lity('//player.vimeo.com/video/'+vimeoid);
		});
	});
}


function hoveritemFunc() {
	const _item = $('.item');
	_item.on('click', function(){
		_item.removeClass('on');
		$(this).addClass('on');
	});
}


var PageEvents = {
	loadFunc: function(){
		navInit();
	},
	commonFunc: function(){
		this.pjaxReadyFunc();
		winH = $(window).height();
		winW = $(window).width();	
		body.removeClass('navwh navbk');
		pageScroll();
		elFunc('.el');
		navWhite();
		txtEffectInit('.txef');
		parallaxImage();
		expandFunc();
		lityFunc();
		getbgColorFunc();
		hoveritemFunc();
		OBSERVER.observeEvents(1);
	},
	pjaxReadyFunc: function(){
	},
	topFunc: async function(){
		body.addClass('top');
		await kvSlider();
		const date_en = document.getElementById('date_en');
		const time_en = document.getElementById('time_en');
		const date_jp = document.getElementById('date_jp');
		const time_jp = document.getElementById('time_jp');
		timetick(date_en,time_en,date_jp,time_jp);
	},
	projectlistFunc: function(){
		spitemShow();
		window.addEventListener('resize', function () {
			winH = $(window).height();
			winW = $(window).width();
			spitemShow();
		});
	},
	singleFunc: function(){
		singleH1();
	}
};

function pjaxSettings(){

	barba.use(barbaPrefetch);

	const replaceBogo = function(data){
		let target = '#wrapper';
		if(data){
			target = data.next.html;
		}
		const $newPageBogo = $(target).find('.bogo-language-switcher').html();
		body.find('#bogo').html($newPageBogo);
		$('#bogo').find('a').addClass('nopj');
	}
	replaceBogo();

	const replaceHead = function(data){
		const head = document.head;
		const newPageRawHead = data.next.html.match(/<head[^>]*>([\s\S.]*)<\/head>/i)[0];
		const newPageHead = document.createElement('head');
		newPageHead.innerHTML = newPageRawHead;
		const removeHeadTags = [ 
			"meta[name='keywords']"
			,"meta[name='description']"
			,"meta[property^='og']"
			,"meta[name^='twitter']"
			,"meta[itemprop]"
			,"link[itemprop]"
			,"link[rel='prev']"
			,"link[rel='next']"
			,"link[rel='canonical']"
		].join(',');
		const headTags = head.querySelectorAll(removeHeadTags)
		for(let i = 0; i < headTags.length; i++ ){
			head.removeChild(headTags[i]);
		}
		const newHeadTags = newPageHead.querySelectorAll(removeHeadTags)
		for(let i = 0; i < newHeadTags.length; i++ ){
			head.appendChild(newHeadTags[i]);
		}
	}

	var eventDelete = function eventDelete(e) {
		if (e.currentTarget.href === window.location.href) {
			e.preventDefault()
			e.stopPropagation()
			return
		}
	}
	const links = Array.prototype.slice.call(document.querySelectorAll('a[href]'));
		links.forEach(function (link) {
			link.addEventListener('click', function (e) {
			eventDelete(e)
		}, false)
	})

	var gaPush = function gaPush(pagename) {
		if (typeof ga === 'function' && Barba.HistoryManager.history.length >= 1) {
			ga('send', 'pageview', pagename);
		}
		if (typeof gtag === 'function' && Barba.HistoryManager.history.length >= 1) { 
			// gtag('config', 'G-Xxxxxxxx', {'page_path': pagename}); 
		}
	}

	var preventSettings = function preventSettings(el) {
		let href = el.getAttribute('href');
		if(href == null){
			href = el.getAttribute('xlink:href');
		}
		if(el.classList.contains('nopj')){
			return true;
		}
		let site_url = location.protocol + '//' + location.host;
		if (!href.startsWith(site_url)) {
			el.setAttribute('target','_blank');
			return true;
		}
		let url = location.protocol + '//' + location.host + location.pathname;
		let extract_hash = href.replace(/#.*$/,"");
		if (href.startsWith(location.protocol + '//' + location.host)) {
			if (href.indexOf('#') > -1 && extract_hash != url ){
				return false;
			}
		}
		if (/\.(xlsx?|docx?|pptx?|pdf|jpe?g|png|gif|svg)/.test(href.toLowerCase())) {
			el.setAttribute('target','_blank');
			return true;
		}
	}

	async function scroll() {
		await new Promise((resolve, reject) => {
			if(location.hash){
				var anchor = document.querySelector( location.hash );
				if(anchor){
					var target = location.hash;
					gsap.to(window, { duration:0, scrollTo:target, ease:'power2', onComplete:function(){
						resolve();
					}});
				}else{
					gsap.to(window, { duration:0.6, scrollTo:0, ease:'power2', onComplete:function(){
						resolve();
					}});
				}
			}else{
				gsap.to(window, { duration:0.6, scrollTo:0, ease:'power2', onComplete:function(){
					resolve();
				}});
			}
		})
	}

	var $footerelm = $('footer')
	barba.init({
		prevent: function prevent(_ref) {
			var el = _ref.el;
			return preventSettings(el);
		},
		timeout: 10000,			
		transitions: [{
			name: 'transition',
			sync:true,
			leave:function(data) {
				const done = this.async();
				gsap.to('footer', {opacity:0, duration:0.2})
				gsap.to(data.current.container, {opacity:0, duration:0.2, onComplete:function(){
					ScrollSmootherKill();
					done();
				}});
			},
			enter:function(data) {
				const done = this.async();
				const $elm = $(data.next.container);
				$elm.css({'opacity':0});
				done();
			}
		}]
	})

	barba.hooks.beforeLeave(() => {
		body.removeClass('ready top inpage topready navopen navwh navbk scrldown single_page');
	});

	barba.hooks.beforeEnter((data) => {
		replaceHead(data);
		replaceBogo(data);
		if( OBSERVER.handlers.length ){
			OBSERVER.clearEvents();
		}
		gaPush(location.pathname);
	});
	barba.hooks.after((data) => {
		async function callerFun(){
			await scroll();
			pageCheck();
			gsap.to('footer', {opacity:1, duration:0.6})
			gsap.to(data.next.container, {opacity:1, duration:0.6, onComplete:function(){
			}});
		}
		$('#container').imagesLoaded( function() {
			callerFun();
		});
	});
}

function pageCheck(){
	PageEvents.commonFunc();

	if( document.getElementById('top') ){
		PageEvents.topFunc();
	}else{
		body.addClass('inpage');
	}
	if( document.getElementById('project') ){
		PageEvents.projectlistFunc();
	}
	if( document.getElementById('single') ){
		PageEvents.singleFunc();
		body.addClass('single_page');
	}
	body.addClass('ready');
}

function initialize(){
	window.addEventListener('DOMContentLoaded', function(){
		PageEvents.loadFunc();
		pageCheck();
		pjaxSettings();
	});
}

initialize();

})();
})( jQuery );

}